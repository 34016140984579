"use client";

import React from "react";
import { NewsletterForm } from "@/components/common/MailingList/NewsLetterForm";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import classNames from "classnames";

import * as styles from "./MailingList.module.scss";

// https://noshowspace.us4.list-manage.com/subscribe/post?u=2c23c941db491981cd2581c2c&amp;id=a56e753c00&amp;f_id=00b36feaf0

const MailingList = ({ title, className }) => {
  const MAILCHIMP_URL = process.env.NEXT_PUBLIC_MAILCHIMP_URL;

  return (
    <div className={classNames(styles.mailingList, className)}>
      <div id="mailform-container" className={styles.mailformContainer}>
        <MailchimpSubscribe
          url={MAILCHIMP_URL}
          render={(props) => {
            const { subscribe, status, message } = props || {};
            return (
              <NewsletterForm
                title={title}
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export { MailingList };
